@tailwind components;
@tailwind utilities;

/*
tailwind base was commented out because it messed up the Markdown formatting from react-markdown,
because it removes a bunch of default styles that are needed for the markdown to render properly (links, paragraphs, etc.).
This font-family setting was copied from tailwind base at https://unpkg.com/tailwindcss@3.4.1/src/css/preflight.css
because sans-serif is what we want.
*/
/* @tailwind base; */
html,
:host {
  font-family: theme('fontFamily.sans', ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"); /* 4 */
}

body {
  margin: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

.App {
  max-height: 100vh;
}

.App-header {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 15px;
  padding-bottom: 10px;
  text-align: center;
}

.loading {
  padding-top: 100px;
}

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
}

.footer {
  grid-area: footer;
}

.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
              "sidebar content content"
              "footer footer footer";
}

.box {
  border-radius: 5px;
}

.ant-carousel .slick-dots li button{
  background-color: gray;
}
.ant-carousel .slick-dots li.slick-active button{
  background-color: black;
}

.ant-slider-horizontal.ant-slider-with-marks {
  margin: 0;
}

.ant-slider .ant-slider-mark {
  font-size: 10px;
}

.yarl__button {
  display: flex;
}
.yarl__slide {
  flex-direction: column;
}

.custom-button {
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
              "left-col right-col"
              "right-col"
              "right-col";
  grid-template-columns: 125px 125px;
}

.left-col {
  display: flex;
  justify-content: center;
}

.ant-modal-root .ant-modal-mask {
  z-index: 10000;
}
.ant-modal-root .ant-modal-wrap {
  z-index: 10001;
}
.ant-popover {
  z-index: 10300;
}
.ant-image-preview-mask {
  z-index: 10000;
}
.ant-image-preview-operations-wrapper {
  z-index: 10081;
}

.contact-message textarea {
  min-height: 200px;
}

.favourite-button:hover {
  color: rgb(244 114 182) !important;
}

.damage-level-info-button .ant-btn-link {
  color: white !important;
}
.damage-level-info-button:hover .ant-btn-link {
  color: white !important;
}
.damage-level-info-button .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: lightgray !important;
}

@media screen and (max-width: 768px) {
  .lightbox-painting-description {
    flex-direction: column;
  }
  .lightbox-painting-info {
    max-width: calc(100vw - 200px);
  }
  .lightbox-painting-header-container {
    justify-content: start;
    margin-left: 10px;
  }
  .lightbox-painting-description-divider {
    display: none;
  }
  .lightbox-painting-description-item {
    margin: 0 !important;
  }
  .yarl__slide_image {
    max-height: 100% !important;
    max-width: 100% !important;
  }
  .yarl__toolbar {
    flex-direction: column;
    left: calc(100vw - 60px) !important;
  }
  /*
  hide the zoom buttons on mobile cause you can pinch-zoom and they get in the way
  */
  .yarl__button:nth-child(1) {
    display: none;
  }
  .yarl__button:nth-child(2) {
    display: none;
  }

  .painting-lightbox-buttons {
    flex-direction: column;
  }

  .price-table .ant-table-container {
    overflow-x: scroll;
  }
  /* damage level modal in lightbox is too wide on mobile */
  .damage-level-modal {
    min-width: unset;
  }

  /*
  The following are for styling the hamburger menu that is used for the sidebar
  on mobile
  */
  .wrapper {
    display: unset;
  }
  .sidebar {
    display: flex;
  }
  .sidebar .ant-menu .ant-menu-submenu-title .anticon {
    font-size: 30px;
  }
  .sidebar .ant-menu-horizontal .ant-menu-submenu {
    padding-top: 10px;
  }
}

.sidebar .ant-menu-horizontal .ant-menu-submenu-title [role=img] {
  color: white;
}

/* Only for NOT mobile */
@media screen and not (max-width: 768px) {
  .damage-level-modal {
    min-width: max(50vw, 800px);
  }
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}